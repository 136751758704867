import "./styles.css";
import throttle from "lodash/throttle";
import PartySocket from "partysocket";
import { createMessage, parseUpdateMessage } from "./types";

// Function to get room ID
const getRoomId = () => {
  let room = window.location.pathname;
  if (room.startsWith("/")) room = room.slice(1);
  if (room.endsWith("/")) room = room.slice(0, -1);
  return room.replaceAll("/", "-") || "default";
};

const room = getRoomId();
console.log("room", room);
const socket = new PartySocket({
  host: window.location.host, // Change to your server's host
  room,
});

// Send message on form submission
document.getElementById("message-form")?.addEventListener("submit", (event) => {
  event.preventDefault();
  const input = document.getElementById("message-input") as HTMLInputElement;
  const messageContent = input.value;
  if (messageContent) {
    socket.send(createMessage(messageContent));
    input.value = ""; // Clear input after sending
  }
});

let messages: Array<{ content: string }> = [];

// Update UI when new messages are received
const updateUI = throttle((event: WebSocketEventMap["message"]) => {
  const messageUpdate = parseUpdateMessage(event.data);  // Parse the incoming message
  messages = messageUpdate.messages;

  // Render messages to the DOM
  const messagesContainer = document.getElementById("messages");
  if (messagesContainer) {
    messagesContainer.innerHTML = ""; // Clear the old messages
    messages.forEach((msg) => {
      const messageElement = document.createElement("div");
      messageElement.textContent = msg.content;
      messagesContainer.appendChild(messageElement); // Append each message to the container
    });
  }
}, 50);

// Listen to incoming messages and update the UI
socket.addEventListener("message", updateUI);