import z from "zod";

export const GO_AWAY_SENTINEL = JSON.stringify({ type: "goAway" });
export const SLOW_DOWN_SENTINEL = JSON.stringify({ type: "slowDown" });

// Define the message type
const MessageSchema = z.object({
  type: z.literal("message"),
  content: z.string(),
});

// Server response for messages
const MessageUpdateSchema = z.object({
  type: z.literal("update"),
  messages: z.array(MessageSchema),
});

// Parse incoming message
export const parseMessage = (message: string) => {
  return MessageSchema.parse(JSON.parse(message));
};

// Create a message object
export const createMessage = (content: string) => {
  return JSON.stringify(
    MessageSchema.parse({
      type: "message",
      content,
    })
  );
};

// Parse update message
export const parseUpdateMessage = (message: string) => {
  return MessageUpdateSchema.parse(JSON.parse(message));
};

// Create update message
// Create update message
export const createUpdateMessage = (messages: Array<{ content: string }>) => {
  // Ensure each message has the correct structure
  const structuredMessages = messages.map(({ content }) =>
    MessageSchema.parse({ type: "message", content })
  );

  return JSON.stringify(
    MessageUpdateSchema.parse({
      type: "update",
      messages: structuredMessages,
    })
  );
};

